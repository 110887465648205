@tailwind base;
@tailwind components;
@tailwind utilities;

/* Capacitor variables needed to use in JS */
:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

.dark {
  @apply text-gray-50 bg-gray-800;
}

.dark ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* Scroll bar styles */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  @apply bg-transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-400 dark:bg-gray-700 rounded;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500 dark:bg-gray-400;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* --------------------------- */
/* Editor */
/* --------------------------- */
/* List and Prompt reason */
.contenteditable:before {
  content: attr(placeholder);
  @apply text-gray-500;
  display: block;
  position: absolute;
  font-family: "Campton", sans-serif;
}

/* Slate editor */
.editing .spoiler {
  background-color: transparent !important;
  cursor: text;
  filter: none !important;
  padding-left: 0px;
  padding-right: 0px;
}

/* --------------------------- */
/* Lists */
/* --------------------------- */
.list-comma,
.list-bullet {
  display: inline;
  list-style: none;
}
.list-comma li,
.list-bullet li {
  display: inline;
}
.list-comma li:after {
  content: ", ";
}
.list-bullet li:after {
  content: " • ";
}
.list-comma li:last-child:after,
.list-bullet li:last-child:after {
  content: "";
}

/* --------------------------- */
/* Prose */
/* --------------------------- */
.aligncenter {
  display: flex;
  justify-content: center;
}

.prose code::before,
.prose code::after {
  content: "";
}

/* Wordpress related */
.prose .aligncenter {
  @apply flex flex-col items-center text-center;
}
.prose .wp-block-embed-youtube .wp-block-embed__wrapper iframe {
  @apply mx-auto;
}
.prose .wp-block-image .alignright {
  @apply float-right;
}
.prose iframe {
  max-width: 95vw;
}

.wp-block-gallery {
  @apply flex flex-col lg:flex-row gap-4 justify-between;
}

.wp-block-gallery.columns-2 {
  @apply grid grid-cols-2;
}
.wp-block-gallery.columns-2 .size-full {
  @apply col-span-full flex justify-center;
}

.fade-bottom-sm {
  mask-image: linear-gradient(to bottom, black 70%, transparent);
}

.fade-bottom-md {
  mask-image: linear-gradient(to bottom, black 50%, transparent);
}

.fade-bottom-lg {
  mask-image: linear-gradient(to bottom, black 10%, transparent);
}

@layer base {
  :root {
    --background: #ffffff;
    --foreground: #111827;

    --card: #e2e8f0;
    --card-foreground: #1f2937;

    --popover: #e2e8f0;
    --popover-foreground: #1f2937;

    /* --primary: #ffffff; 
    --primary-foreground: #111827; */

    /* Hardcover purple */
    --primary: #4f46e5;
    --primary-foreground: #e0e7ff;

    --secondary: #d1d5db;
    --secondary-foreground: #374151;

    --tertiary: #e5e7eb;
    --tertiary-foreground: #9ca3af;

    --muted: #a1a1aa;
    --muted-foreground: #4b5563;

    /* yellow */
    --accent: #f59e0b;
    --accent-foreground: #362200;

    /* red */
    --destructive: #dc2626;
    --destructive-foreground: #fecaca;

    /* green */
    --success: #10b981;
    --success-foreground: #06271e;

    /* blue */
    --info: #2563eb;
    --info-foreground: #0b142e;

    --border: #e5e7eb;
    --input: #d1d5db;
    --ring: #4f46e5;

    --radius: 0.5rem;
  }

  .dark {
    --background: #111827;
    --foreground: #ffffff;

    --card: #0f172a;
    --card-foreground: #f3f4f6;

    --popover: #0f172a;
    --popover-foreground: #f3f4f6;

    --primary: #4f46e5;
    --primary-foreground: #e0e7ff;

    --secondary: #374151;
    --secondary-foreground: #d1d5db;

    --tertiary: #9ca3af;
    --tertiary-foreground: #e5e7eb;

    --muted: #4b5563;
    --muted-foreground: #a1a1aa;

    /* yellow */
    --accent: #f59e0b;
    --accent-foreground: #362200;

    /* red */
    --destructive: #dc2626;
    --destructive-foreground: #fecaca;

    /* green */
    --success: #10b981;
    --success-foreground: #06271e;

    /* blue */
    --info: #2563eb;
    --info-foreground: #0b142e;

    --border: #111827;
    --input: #6b7280;
    --ring: #fde047;
  }
}

/* Code Blocks with title */
.prose .code {
  max-width: 95vw;
  @apply border border-gray-600 rounded-lg overflow-hidden;
}
.prose .code p.code-file {
  display: flex;
  background-color: var(--tw-prose-pre-bg);
  @apply py-4 px-2 font-mono text-base text-gray-200 m-0 max-w-full;
}
.prose .code p.code-file:before {
  display: inline-block;
  content: "🗎 ";
  -webkit-mask-size: cover;
  mask-size: cover;
  width: 14px;
  height: 20px;
  @apply mx-2 bg-gray-50 dark:bg-gray-900;
}

.prose .code pre.wp-block-code {
  display: block;
  white-space: pre-wrap;
  @apply p-4 rounded-lg rounded-t-none border-t-gray-600 overflow-scroll;
  scrollbar-width: thin;
}
.prose .shcb-language {
  display: none;
}
